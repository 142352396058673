<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    label="Commission"
    icon="paid"
    :loading="loading"
  />
</template>

<script>
import { FormSelect } from '@/components/Inputs';

export default {
  components: {
    FormSelect,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noPercentage: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    agent: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    default_options: [],
  }),
  computed: {
    options() {
      if (!this.agent) return [];
      return [...this.agent.commissions].map((x) => ({
        id: x.id || x.agent_commission_id,
        name: x.name || (this.noPercentage ? x.description : `${x.description} (${x.percentage}%)`),
      }));
    },
    result: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.$emit('change', this.options.find((x) => x.id === val));
      },
    },
  },
};
</script>
