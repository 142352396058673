<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    label="Programme"
    icon="description"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    studentId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    vuex_module: 'studentProgrammes',
    primaryKey: 'programme_id',
  }),
  methods: {
    getData() {
      this.$store.dispatch(`${this.vuex_module}/${this.vuex_action || 'getOptions'}`, {
        id: this.studentId,
        cb: () => {
          this.$emit('ready', this.options);
        },
      });
    },
  },
};
</script>
