<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    :label="label || 'Accommodation Type'"
    icon="label_outline"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    showWithUsers: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    vuex_module: 'packageItems',
    vuex_action: 'getAccommodationTypesOptions',
    vuex_getter: 'accommodationOptions',
    primaryKey: 'package_item_id',
  }),
};
</script>
