<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    label="Activity"
    icon="local_activity"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    showWithUsers: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    vuex_module: 'activities',
    primaryKey: 'activity_id',
  }),
  methods: {
    getData() {
      const params = {};

      if (this.showWithUsers) params.show_with_users = true;

      this.$store.dispatch(`${this.vuex_module}/${this.vuex_action || 'getOptions'}`, {
        cb: () => {
          if (this.result) {
            this.$emit('change', this.options.filter((x) => x[this.primaryKey] === this.result)[0]);
          }
        },
        params,
      });
    },
  },
};
</script>
