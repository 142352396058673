<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    label="Classroom Name"
    icon="note"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    name: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    vuex_module: 'classroomNames',
    primaryKey: 'classroom_name_id',
  }),
  computed: {
    result: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.$emit('change', this.options.find((x) => x[this.primaryKey] === val));
      },
    },
  },
};
</script>
