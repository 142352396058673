<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    label="Teacher"
    icon="person"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  data: () => ({
    vuex_module: 'teachers',
    primaryKey: 'user_id',
  }),
  computed: {
    options() {
      const options = [...this.$store.getters[`${this.vuex_module}/${this.vuex_getter || 'options'}`]] || [];
      if (this.nullOption && !options.map((x) => x.id).includes('null')) options.unshift({ id: 'null', name: this.nullOption });
      if (this.allOption && !options.map((x) => x.id).includes('all')) options.unshift({ id: 'all', name: this.allOption });

      return options.sort((a, b) => (a.name < b.name ? -1 : 1));
    },
  },
};
</script>
