<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    label="Staff"
    icon="person"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  data: () => ({
    vuex_module: 'staff',
    primaryKey: 'user_id',
  }),
  methods: {
    getData() {
      this.$store.dispatch(`${this.vuex_module}/${this.vuex_action || 'getOptions'}`, {
        cb: () => {
          if (this.result) {
            this.$emit('change', this.options.filter((x) => x[this.primaryKey] === this.result)[0]);
          }
        },
      });
    },
  },
};
</script>
