<template>
  <FormSelect
    v-model="result"
    :options="showedOptions"
    :disabled="disabled"
    :rules="rules"
    :icon="icon"
    :label="label"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Payment Method',
    },
    withDeleted: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    vuex_module: 'paymentMethods',
    primaryKey: 'payment_method_id',
  }),
  computed: {
    showedOptions() {
      if (!this.withDeleted) {
        return this.options.filter((x) => !x.deleted_at);
      }
      return this.options;
    },
  },
};
</script>
