<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    label="File Type"
    icon="description"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    onlyAdminTypes: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    vuex_module: 'fileTypes',
    primaryKey: 'file_type_id',
  }),
  computed: {
    vuex_action() {
      return this.onlyAdminTypes && 'getAdminOptions';
    },
  },
};
</script>
