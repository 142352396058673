<template>
  <FormAutocomplete
    v-model="result"
    :min-len="minLen"
    :options="filteredOptions"
    :option-getter="filterOptions"
    :disabled="disabled"
    :rules="rules"
    label="Category"
    icon="description"
    :loading="loading"
  />
</template>

<script>
import AutocompleteMixin from './autocompleteMixin';

export default {
  mixins: [AutocompleteMixin],
  data: () => ({
    vuex_module: 'moneyflowCategories',
    primaryKey: 'moneyflow_category_id',
    filteredOptions: [],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    filterOptions(search) {
      if (!search) {
        this.filteredOptions = this.options;
      } else {
        this.filteredOptions = this.options.filter(
          (x) => x.name.toLowerCase().indexOf(search.toLowerCase()) > -1,
        );
      }
    },
  },
};
</script>
