<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    label="Text related"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  data: () => ({
    vuex_module: 'examQuestions',
    vuex_getter: 'textsOptions',
    vuex_action: 'getTextsOptions',
    primaryKey: 'placement_question_id',
  }),
};
</script>
