<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    :label="label"
    :loading="loading"
  />
</template>

<script>
import { FormSelect } from '@/components/Inputs';
import moment from 'moment';

export default {
  components: {
    FormSelect,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    nullOption: {
      type: String,
      default: null,
    },
    allOption: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Month',
    },
  },
  data: () => ({
    default_options: [],
  }),
  computed: {
    options() {
      const options = this.default_options || [];
      if (this.nullOption && !options.map((x) => x.id).includes('null')) options.unshift({ id: 'null', name: this.nullOption });
      if (this.allOption && !options.map((x) => x.id).includes('all')) options.unshift({ id: 'all', name: this.allOption });

      return options;
    },
    result: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  mounted() {
    for (let i = 1; i <= 12; i += 1) {
      const month = moment(i, 'M').format('MMMM');

      this.default_options.push({
        id: i,
        name: month,
      });
    }
  },
};
</script>
