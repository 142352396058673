<template>
  <FormAutocomplete
    v-model="result"
    :min-len="minLen"
    :options="filteredOptions"
    :option-getter="filterOptions"
    :label="label"
    :rules="rules"
    :asterisk="asterisk"
    :disabled="disabled"
    icon="flag"
    :loading="loading"
  />
</template>

<script>
import AutocompleteMixin from './autocompleteMixin';

export default {
  mixins: [
    AutocompleteMixin,
  ],
  props: {
    label: {
      type: String,
      default: 'Select Country',
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    vuex_module: 'countries',
    primaryKey: 'country_id',
    filteredOptions: [],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    filterOptions(search) {
      if (!search) {
        this.filteredOptions = this.options;
      } else {
        this.filteredOptions = this.options
          .filter((x) => x.name.toLowerCase().indexOf(search.toLowerCase()) > -1);

        if (this.filteredOptions.length === 1) {
          this.result = this.filteredOptions[0].id;
        }
      }
    },
  },
};
</script>
