import { FormSelect } from '@/components/Inputs';

export default {
  components: {
    FormSelect,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    nullOption: {
      type: String,
      default: null,
    },
    allOption: {
      type: String,
      default: null,
    },
    newOption: {
      type: String,
      default: null,
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    result: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);

        this.$emit('change', this.options.find((x) => x.id === val));
      },
    },
    options() {
      const options = [...this.$store.getters[`${this.vuex_module}/${this.vuex_getter || 'options'}`]] || [];
      if (this.nullOption && !options.map((x) => x.id).includes('null')) options.unshift({ id: 'null', name: this.nullOption });
      if (this.newOption && !options.map((x) => x.id).includes('new')) options.unshift({ id: 'new', name: this.newOption });
      if (this.allOption && !options.map((x) => x.id).includes('all')) options.unshift({ id: 'all', name: this.allOption });

      return options;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(force = false) {
      return new Promise((resolve) => {
        this.loading = true;

        this.$store.dispatch(`${this.vuex_module}/${this.vuex_action || 'getOptions'}`, {
          force,
          cb: () => {
            this.$emit('ready', this.options);
            this.loading = false;
            resolve();
          },
        });
      });
    },
  },
};
