<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    icon="brightness_6"
    :label="label || 'Periods'"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    label: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    vuex_module: 'periods',
    primaryKey: 'class_schedule_id',
  }),
};
</script>
