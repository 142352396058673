<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    label="Department"
    icon="space_dashboard"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  data: () => ({
    vuex_module: 'departments',
    primaryKey: 'departments_id',
  }),
};
</script>
