<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    :label="label || 'Course'"
    icon="flag"
    :loading="loading"
    :multiple="multiple"
  >
    <template
      v-if="result"
      slot="button"
    >
      <md-button
        class="md-just-icon md-sm md-round md-raised md-danger clear-button"
        @click="result = null"
      >
        <md-icon>close</md-icon>
        <md-tooltip>Clear</md-tooltip>
      </md-button>
    </template>
  </FormSelect>
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    label: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    vuex_module: 'courses',
    primaryKey: 'course_id',
  }),
  watch: {
    disabled() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.$store.dispatch(`${this.vuex_module}/${this.vuex_action || 'getOptions'}`, {
        cb: () => {
          this.$emit('ready', this.options);
        },
        params: {
          show_inactives: this.disabled,
        },
      });
    },
  },
};
</script>
