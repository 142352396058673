<template>
  <FormAutocomplete
    ref="autocomplete"
    v-model="result"
    :min-len="3"
    :options="options"
    :option-getter="getData"
    :label="label"
    icon="people"
    :disabled="disabled"
    :loading="loading"
  />
</template>

<script>
import AutocompleteMixin from './autocompleteMixin';

export default {
  mixins: [
    AutocompleteMixin,
  ],
  props: {
    withAgent: {
      type: Boolean,
      default: false,
    },
    withApp: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Search Student',
    },
  },
  data: () => ({
    vuex_module: 'students',
    primaryKey: 'student_id',
  }),
  methods: {
    getData(search) {
      if (!search || search === '') {
        this.reset();
      }

      const params = {
        search,
        with_agent: this.withAgent,
      };
      if (this.withApp) {
        params.with_app = true;
      }

      this.$store.dispatch(`${this.vuex_module}/getOptions`, params);
    },
    reset() {
      this.$refs.autocomplete.onReset();
    },
  },
};
</script>
