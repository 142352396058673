<template>
  <div>
    <FormSelect
      v-if="!loaded || options.length"
      v-model="result"
      :options="options"
      :disabled="disabled"
      :rules="rules"
      label="Classroom"
      icon="description"
      :loading="loading"
    />
    <div
      v-else-if="loaded"
      class="alert alert-danger"
    >
      <slot name="error" />
    </div>
  </div>
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    parameters: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    vuex_module: 'classrooms',
    primaryKey: 'classroom_id',
    loaded: false,
  }),
  methods: {
    getData() {
      this.$store.dispatch(`${this.vuex_module}/${this.vuex_action || 'getOptions'}`, {
        ...this.parameters,
        cb: () => {
          this.$emit('ready', this.options);
          this.loaded = true;
        },
      });
    },
  },
};
</script>
