<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    :label="label || 'Agent'"
    icon="support_agent"
    :loading="loading"
  >
    <template
      v-if="result"
      slot="button"
    >
      <md-button
        class="md-just-icon md-sm md-round md-raised md-danger clear-button"
        @click="result = null"
      >
        <md-icon>close</md-icon>
        <md-tooltip>Clear</md-tooltip>
      </md-button>
    </template>
  </FormSelect>
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    agentGroups: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    vuex_module: 'agents',
    primaryKey: 'user_id',
    options: [],
  }),
  methods: {
    getData() {
      this.$store.dispatch(`${this.vuex_module}/${this.vuex_action || 'getOptions'}`, {
        cb: (options) => {
          this.options = options;
          if (this.nullOption && !options.map((x) => x.id).includes('null')) options.unshift({ id: 'null', name: this.nullOption });
          if (this.newOption && !options.map((x) => x.id).includes('new')) options.unshift({ id: 'new', name: this.newOption });
          if (this.allOption && !options.map((x) => x.id).includes('all')) options.unshift({ id: 'all', name: this.allOption });

          this.$emit('ready', this.options);
          if (this.result) {
            this.$emit('change', this.options.find((x) => x[this.primaryKey] === this.result));
          }
        },
        filters: {
          agent_group_id: this.agentGroups,
          with_id: this.value,
        },
      });
    },
  },
};
</script>
