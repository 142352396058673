<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    :label="label || 'Letter Layout'"
    icon="view_quilt"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    label: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    vuex_module: 'letterLayouts',
    primaryKey: 'letter_layout_id',
  }),
  methods: {
    selectLast() {
      this.getData(true)
        .then(() => {
          const last = this.options[this.options.length - 1];
          this.$emit('input', last[this.primaryKey]);
        });
    },
  },
};
</script>
