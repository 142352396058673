import { FormAutocomplete } from '@/components/Inputs';

export default {
  components: {
    FormAutocomplete,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    minLen: {
      type: Number,
      default: 0,
    },
    parameters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    options() {
      return this.$store.getters[`${this.vuex_module}/options`];
    },
    optionsHashMap() {
      return this.options.reduce((a, b) => {
        a[b[this.primaryKey]] = b;
        return a;
      }, {});
    },
    result: {
      get() {
        return this.optionsHashMap[this.value];
      },
      set(val) {
        this.$emit('input', val);
        this.$emit('change', this.optionsHashMap[val]);
      },
    },
  },
  methods: {
    getData() {
      this.$store.dispatch(`${this.vuex_module}/getOptions`, {
        ...this.parameters,
        cb: () => {
          this.$emit('ready', this.options);
          this.filterOptions();
        },
      });
    },
  },
};
