<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    :label="label"
    :multiple="multiple"
    :loading="loading"
  />
</template>

<script>
import { FormSelect } from '@/components/Inputs';

export default {
  components: {
    FormSelect,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Days',
    },
    nullOption: {
      type: String,
      default: null,
    },
    allOption: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    default_options: [
      { id: 1, name: 'Monday' },
      { id: 2, name: 'Tuesday' },
      { id: 3, name: 'Wednesday' },
      { id: 4, name: 'Thursday' },
      { id: 5, name: 'Friday' },
      { id: 6, name: 'Saturday' },
      { id: 0, name: 'Sunday' },
    ],
  }),
  computed: {
    options() {
      const options = this.default_options || [];
      if (this.nullOption && !options.map((x) => x.id).includes('null')) options.unshift({ id: 'null', name: this.nullOption });
      if (this.allOption && !options.map((x) => x.id).includes('all')) options.unshift({ id: 'all', name: this.allOption });

      return options;
    },
    result: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
