<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled || !options.length"
    :rules="rules"
    :label="label"
    :multiple="multiple"
    icon="description"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    filter: {
      type: Function,
      default: null,
    },
    onlyCommissionable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Item to Add',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    vuex_module: 'packageItems',
    primaryKey: 'package_item_id',
    vuex_getter: null,
    vuex_action: null,
  }),
  computed: {
    options() {
      let options = this.$store.getters[`${this.vuex_module}/${this.vuex_getter || 'options'}`] || [];

      if (this.filter) options = options.filter((x) => this.filter(x));

      if (this.nullOption && !options.map((x) => x.id).includes('null')) options.unshift({ id: 'null', name: this.nullOption });
      if (this.allOption && !options.map((x) => x.id).includes('all')) options.unshift({ id: 'all', name: this.allOption });

      return options;
    },
  },
  created() {
    if (this.onlyCommissionable) {
      this.vuex_getter = 'getCommissionableOptions';
      this.vuex_action = 'commissionableOptions';
    }
  },
};
</script>
