<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    :label="label || 'Template'"
    icon="description"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    label: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    vuex_module: 'letterTemplates',
    primaryKey: 'letter_template_id',
  }),
};
</script>
