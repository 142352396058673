<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    :label="label"
    :multiple="multiple"
    icon="donut_large"
    :loading="loading"
  />
</template>

<script>
import { FormSelect } from '@/components/Inputs';

export default {
  components: {
    FormSelect,
  },
  props: {
    value: {
      type: [Number, Array],
      default: null,
    },
    label: {
      type: String,
      default: 'Status',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    nullOption: {
      type: String,
      default: null,
    },
    allOption: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    default_options: [
      {
        id: 'Waiting for Approval',
        name: 'Waiting for Approval',
      },
      {
        id: 'Unpaid',
        name: 'Unpaid',
      },
      {
        id: 'Partially Paid',
        name: 'Partially Paid',
      },
      {
        id: 'Fully Paid',
        name: 'Fully Paid',
      },
      {
        id: 'Pre-Enrolled',
        name: 'Pre-Enrolled',
      },
      {
        id: 'Enrolled',
        name: 'Enrolled',
      },
      {
        id: 'Holiday',
        name: 'Holiday',
      },
      {
        id: 'On-Hold',
        name: 'On-Hold',
      },
      {
        id: 'Inactive',
        name: 'Inactive',
      },
      {
        id: 'Expelled',
        name: 'Expelled',
      },
      {
        id: 'Completed',
        name: 'Completed',
      },
      {
        id: 'Finished',
        name: 'Finished',
      },
      {
        id: 'Cancelled',
        name: 'Cancelled',
      },
    ],
  }),
  computed: {
    options() {
      const options = this.default_options || [];
      if (this.nullOption && !options.map((x) => x.id).includes('null')) options.unshift({ id: 'null', name: this.nullOption });
      if (this.allOption && !options.map((x) => x.id).includes('all')) options.unshift({ id: 'all', name: this.allOption });

      return options;
    },
    result: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
