<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    icon="spellcheck"
    :label="label"
    :loading="loading"
  >
    <template
      v-if="result && !noClearBtn"
      slot="button"
    >
      <md-button
        class="md-just-icon md-sm md-round md-raised md-danger clear-button"
        @click="result = null"
      >
        <md-icon>close</md-icon>
        <md-tooltip>Clear</md-tooltip>
      </md-button>
    </template>
  </FormSelect>
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    label: {
      type: String,
      default: 'Level',
    },
    noClearBtn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    vuex_module: 'levels',
    primaryKey: 'course_level_id',
  }),
};
</script>
