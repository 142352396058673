<template>
  <FormAutocomplete
    ref="ac"
    v-model="result"
    :min-len="minLen"
    :options="filteredOptions"
    :option-getter="filterOptions"
    :disabled="disabled"
    :label="label"
    icon="description"
    :loading="loading"
  />
</template>

<script>
import AutocompleteMixin from './autocompleteMixin';

export default {
  mixins: [
    AutocompleteMixin,
  ],
  props: {
    label: {
      type: String,
      default: 'Classroom',
    },
  },
  data: () => ({
    vuex_module: 'classrooms',
    primaryKey: 'classroom_id',
    filteredOptions: [],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    clear() {
      this.$refs.ac.onReset();
    },
    filterOptions(search) {
      if (!search) {
        this.filteredOptions = this.options;
      } else {
        this.filteredOptions = this.options
          .filter((x) => {
            const name = `${x.classroom_code.toLowerCase()} | ${x.classroom_name.name.toLowerCase()}`;

            return name.indexOf(search.toLowerCase()) > -1;
          });
      }
    },
  },
};
</script>
