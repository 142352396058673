<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    label="Request Type"
    icon="format_list_bulleted"
    :loading="loading"
  />
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    onlyStudentRequests: {
      type: Boolean,
      default: false,
    },
    noStudentRequests: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    vuex_module: 'requestTypes',
    primaryKey: 'request_type_id',
  }),
  methods: {
    getData() {
      const params = {};
      if (this.onlyStudentRequests) {
        params.is_student_request = true;
      }
      if (this.noStudentRequests) {
        params.is_student_request = false;
      }

      this.$store.dispatch(`${this.vuex_module}/${this.vuex_action || 'getOptions'}`, {
        params,
        cb: () => {
          this.$emit('ready', this.options);
        },
      });
    },
  },
};
</script>
