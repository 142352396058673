<template>
  <FormSelect
    v-model="result"
    :options="options"
    :disabled="disabled"
    :rules="rules"
    :label="label"
    icon="store"
    :loading="loading"
  >
    <template
      v-if="result"
      slot="button"
    >
      <md-button
        class="md-just-icon md-sm md-round md-raised md-danger clear-button"
        @click="result = null"
      >
        <md-icon>close</md-icon>
        <md-tooltip>Clear</md-tooltip>
      </md-button>
    </template>
  </FormSelect>
</template>

<script>
import SelectMixin from './selectMixin';

export default {
  mixins: [
    SelectMixin,
  ],
  props: {
    agentGroups: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Agency',
    },
  },
  data: () => ({
    vuex_module: 'agencies',
    primaryKey: 'agency_id',
  }),
  methods: {
    getData() {
      this.$store.dispatch(`${this.vuex_module}/${this.vuex_action || 'getOptions'}`, {
        cb: () => {
          if (this.result) {
            this.$emit('change', this.options.filter((x) => x[this.primaryKey] === this.result)[0]);
          }
        },
      });
    },
  },
};
</script>
